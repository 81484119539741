import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";

export async function saveFile(data: ArrayBuffer, fileName: string) {
  if (Capacitor.isNativePlatform()) {
    const base64Data = Buffer.from(data).toString("base64");

    const result = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Documents,
    });

    await Share.share({
      url: result.uri,
    });
  } else {
    const blob = new Blob([data]);
    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = fileName;
    document.body.append(link);
    link.click();
    link.remove();

    URL.revokeObjectURL(blobUrl);
  }
}
